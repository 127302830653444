/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { hex2rgba as e, isNamedColor as t, getNamedColorCopy as r, hsla2rgba as n } from "../../colorUtils.js";
import u from "../../core/Error.js";
import { ColorMatrixEffect as a, OpacityEffect as o, HueRotateEffect as c, BlurEffect as i, DropShadowEffect as l, BloomEffect as s } from "./effects.js";
import { canInterpolateEffects as f, normalizeEffects as p } from "./utils.js";
function h(e, t) {
  function r() {
    this.constructor = e;
  }
  r.prototype = t.prototype, e.prototype = new r();
}
function m(e, t, r, n) {
  var u = Error.call(this, e);
  return Object.setPrototypeOf && Object.setPrototypeOf(u, m.prototype), u.expected = t, u.found = r, u.location = n, u.name = "SyntaxError", u;
}
function g(e, t, r) {
  return r = r || " ", e.length > t ? e : (t -= e.length, e + (r += r.repeat(t)).slice(0, t));
}
function v(e, t) {
  var r,
    n = {},
    u = (t = void 0 !== t ? t : {}).grammarSource,
    a = {
      start: Qe
    },
    o = Qe,
    c = "none",
    i = ")",
    l = ",",
    s = "(",
    f = "%",
    p = "px",
    h = "cm",
    g = "mm",
    v = "in",
    d = "pt",
    y = "pc",
    w = "deg",
    x = "rad",
    A = "grad",
    b = "turn",
    F = "#",
    $ = ".",
    C = "e",
    E = /^[ \t\n\r]/,
    j = /^[a-z\-]/,
    O = /^[0-9a-fA-F]/,
    P = /^[+\-]/,
    q = /^[0-9]/,
    k = Be("none"),
    z = Ue("none", !1),
    I = Ue(")", !1),
    R = Ue(",", !1),
    S = Be("whitespace"),
    M = De([" ", "\t", "\n", "\r"], !1, !1),
    N = Be("function"),
    T = Ue("(", !1),
    U = Be("identifier"),
    D = De([["a", "z"], "-"], !1, !1),
    L = Be("percentage"),
    B = Ue("%", !1),
    G = Be("length"),
    H = Ue("px", !1),
    J = Ue("cm", !1),
    K = Ue("mm", !1),
    Q = Ue("in", !1),
    V = Ue("pt", !1),
    W = Ue("pc", !1),
    X = Be("angle"),
    Y = Ue("deg", !1),
    Z = Ue("rad", !1),
    _ = Ue("grad", !1),
    ee = Ue("turn", !1),
    te = Be("number"),
    re = Be("color"),
    ne = Ue("#", !1),
    ue = De([["0", "9"], ["a", "f"], ["A", "F"]], !1, !1),
    ae = De(["+", "-"], !1, !1),
    oe = De([["0", "9"]], !1, !1),
    ce = Ue(".", !1),
    ie = Ue("e", !1),
    le = function () {
      return [];
    },
    se = function (e, t) {
      return {
        type: "function",
        name: e,
        parameters: t || []
      };
    },
    fe = function (e, t) {
      return t.length > 0 ? lt(e, t, 3) : [e];
    },
    pe = function (e) {
      return {
        type: "quantity",
        value: e.value,
        unit: e.unit
      };
    },
    he = function (e) {
      return {
        type: "color",
        colorType: e.type,
        value: e.value
      };
    },
    me = function (e) {
      return e;
    },
    ge = function () {
      return Te();
    },
    ve = function (e) {
      return {
        value: e,
        unit: "%"
      };
    },
    de = function (e) {
      return {
        value: e,
        unit: "px"
      };
    },
    ye = function (e) {
      return {
        value: e,
        unit: "cm"
      };
    },
    we = function (e) {
      return {
        value: e,
        unit: "mm"
      };
    },
    xe = function (e) {
      return {
        value: e,
        unit: "in"
      };
    },
    Ae = function (e) {
      return {
        value: e,
        unit: "pt"
      };
    },
    be = function (e) {
      return {
        value: e,
        unit: "pc"
      };
    },
    Fe = function (e) {
      return {
        value: e,
        unit: "deg"
      };
    },
    $e = function (e) {
      return {
        value: e,
        unit: "rad"
      };
    },
    Ce = function (e) {
      return {
        value: e,
        unit: "grad"
      };
    },
    Ee = function (e) {
      return {
        value: e,
        unit: "turn"
      };
    },
    je = function (e) {
      return {
        value: e,
        unit: null
      };
    },
    Oe = function () {
      return {
        type: "hex",
        value: Te()
      };
    },
    Pe = function (e) {
      return {
        type: "function",
        value: e
      };
    },
    qe = function () {
      return {
        type: "named",
        value: Te()
      };
    },
    ke = function () {
      return parseFloat(Te());
    },
    ze = 0 | t.peg$currPos,
    Ie = ze,
    Re = [{
      line: 1,
      column: 1
    }],
    Se = ze,
    Me = t.peg$maxFailExpected || [],
    Ne = 0 | t.peg$silentFails;
  if (t.startRule) {
    if (!(t.startRule in a)) throw new Error("Can't start parsing from rule \"" + t.startRule + '".');
    o = a[t.startRule];
  }
  function Te() {
    return e.substring(Ie, ze);
  }
  function Ue(e, t) {
    return {
      type: "literal",
      text: e,
      ignoreCase: t
    };
  }
  function De(e, t, r) {
    return {
      type: "class",
      parts: e,
      inverted: t,
      ignoreCase: r
    };
  }
  function Le() {
    return {
      type: "end"
    };
  }
  function Be(e) {
    return {
      type: "other",
      description: e
    };
  }
  function Ge(t) {
    var r,
      n = Re[t];
    if (n) return n;
    if (t >= Re.length) r = Re.length - 1;else for (r = t; !Re[--r];);
    for (n = {
      line: (n = Re[r]).line,
      column: n.column
    }; r < t;) 10 === e.charCodeAt(r) ? (n.line++, n.column = 1) : n.column++, r++;
    return Re[t] = n, n;
  }
  function He(e, t, r) {
    var n = Ge(e),
      a = Ge(t);
    return {
      source: u,
      start: {
        offset: e,
        line: n.line,
        column: n.column
      },
      end: {
        offset: t,
        line: a.line,
        column: a.column
      }
    };
  }
  function Je(e) {
    ze < Se || (ze > Se && (Se = ze, Me = []), Me.push(e));
  }
  function Ke(e, t, r) {
    return new m(m.buildMessage(e, t), e, t, r);
  }
  function Qe() {
    var e;
    return (e = Ve()) === n && (e = We()), e;
  }
  function Ve() {
    var t, r;
    return Ne++, t = ze, _e(), e.substr(ze, 4) === c ? (r = c, ze += 4) : (r = n, 0 === Ne && Je(z)), r !== n ? (_e(), Ie = t, t = le()) : (ze = t, t = n), Ne--, t === n && 0 === Ne && Je(k), t;
  }
  function We() {
    var e, t;
    if (e = [], (t = Xe()) !== n) for (; t !== n;) e.push(t), t = Xe();else e = n;
    return e;
  }
  function Xe() {
    var t, r, u, a;
    return t = ze, _e(), (r = et()) !== n ? (_e(), (u = Ye()) === n && (u = null), _e(), 41 === e.charCodeAt(ze) ? (a = i, ze++) : (a = n, 0 === Ne && Je(I)), a !== n ? (_e(), Ie = t, t = se(r, u)) : (ze = t, t = n)) : (ze = t, t = n), t;
  }
  function Ye() {
    var t, r, u, a, o, c, i, s;
    if (t = ze, (r = Ze()) !== n) {
      for (u = [], a = ze, o = _e(), 44 === e.charCodeAt(ze) ? (c = l, ze++) : (c = n, 0 === Ne && Je(R)), c === n && (c = null), i = _e(), (s = Ze()) !== n ? a = o = [o, c, i, s] : (ze = a, a = n); a !== n;) u.push(a), a = ze, o = _e(), 44 === e.charCodeAt(ze) ? (c = l, ze++) : (c = n, 0 === Ne && Je(R)), c === n && (c = null), i = _e(), (s = Ze()) !== n ? a = o = [o, c, i, s] : (ze = a, a = n);
      Ie = t, t = fe(r, u);
    } else ze = t, t = n;
    return t;
  }
  function Ze() {
    var e, t;
    return e = ze, (t = rt()) === n && (t = nt()) === n && (t = ut()) === n && (t = at()), t !== n && (Ie = e, t = pe(t)), (e = t) === n && (e = ze, (t = ot()) !== n && (Ie = e, t = he(t)), e = t), e;
  }
  function _e() {
    var t, r;
    for (Ne++, t = [], r = e.charAt(ze), E.test(r) ? ze++ : (r = n, 0 === Ne && Je(M)); r !== n;) t.push(r), r = e.charAt(ze), E.test(r) ? ze++ : (r = n, 0 === Ne && Je(M));
    return Ne--, r = n, 0 === Ne && Je(S), t;
  }
  function et() {
    var t, r, u;
    return Ne++, t = ze, (r = tt()) !== n ? (40 === e.charCodeAt(ze) ? (u = s, ze++) : (u = n, 0 === Ne && Je(T)), u !== n ? (Ie = t, t = me(r)) : (ze = t, t = n)) : (ze = t, t = n), Ne--, t === n && (r = n, 0 === Ne && Je(N)), t;
  }
  function tt() {
    var t, r, u;
    if (Ne++, t = ze, r = [], u = e.charAt(ze), j.test(u) ? ze++ : (u = n, 0 === Ne && Je(D)), u !== n) for (; u !== n;) r.push(u), u = e.charAt(ze), j.test(u) ? ze++ : (u = n, 0 === Ne && Je(D));else r = n;
    return r !== n && (Ie = t, r = ge()), Ne--, (t = r) === n && (r = n, 0 === Ne && Je(U)), t;
  }
  function rt() {
    var t, r, u;
    return Ne++, t = ze, _e(), (r = ct()) !== n ? (37 === e.charCodeAt(ze) ? (u = f, ze++) : (u = n, 0 === Ne && Je(B)), u !== n ? (Ie = t, t = ve(r)) : (ze = t, t = n)) : (ze = t, t = n), Ne--, t === n && 0 === Ne && Je(L), t;
  }
  function nt() {
    var t, r, u;
    return Ne++, t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === p ? (u = p, ze += 2) : (u = n, 0 === Ne && Je(H)), u !== n ? (Ie = t, t = de(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === h ? (u = h, ze += 2) : (u = n, 0 === Ne && Je(J)), u !== n ? (Ie = t, t = ye(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === g ? (u = g, ze += 2) : (u = n, 0 === Ne && Je(K)), u !== n ? (Ie = t, t = we(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === v ? (u = v, ze += 2) : (u = n, 0 === Ne && Je(Q)), u !== n ? (Ie = t, t = xe(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === d ? (u = d, ze += 2) : (u = n, 0 === Ne && Je(V)), u !== n ? (Ie = t, t = Ae(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, _e(), (r = ct()) !== n ? (e.substr(ze, 2) === y ? (u = y, ze += 2) : (u = n, 0 === Ne && Je(W)), u !== n ? (Ie = t, t = be(r)) : (ze = t, t = n)) : (ze = t, t = n)))))), Ne--, t === n && 0 === Ne && Je(G), t;
  }
  function ut() {
    var t, r, u;
    return Ne++, t = ze, (r = ct()) !== n ? (e.substr(ze, 3) === w ? (u = w, ze += 3) : (u = n, 0 === Ne && Je(Y)), u !== n ? (Ie = t, t = Fe(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, (r = ct()) !== n ? (e.substr(ze, 3) === x ? (u = x, ze += 3) : (u = n, 0 === Ne && Je(Z)), u !== n ? (Ie = t, t = $e(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, (r = ct()) !== n ? (e.substr(ze, 4) === A ? (u = A, ze += 4) : (u = n, 0 === Ne && Je(_)), u !== n ? (Ie = t, t = Ce(r)) : (ze = t, t = n)) : (ze = t, t = n), t === n && (t = ze, (r = ct()) !== n ? (e.substr(ze, 4) === b ? (u = b, ze += 4) : (u = n, 0 === Ne && Je(ee)), u !== n ? (Ie = t, t = Ee(r)) : (ze = t, t = n)) : (ze = t, t = n)))), Ne--, t === n && (r = n, 0 === Ne && Je(X)), t;
  }
  function at() {
    var e, t;
    return Ne++, e = ze, _e(), (t = ct()) !== n ? (Ie = e, e = je(t)) : (ze = e, e = n), Ne--, e === n && 0 === Ne && Je(te), e;
  }
  function ot() {
    var t, r, u, a;
    if (Ne++, t = ze, 35 === e.charCodeAt(ze) ? (r = F, ze++) : (r = n, 0 === Ne && Je(ne)), r !== n) {
      if (u = [], a = e.charAt(ze), O.test(a) ? ze++ : (a = n, 0 === Ne && Je(ue)), a !== n) for (; a !== n;) u.push(a), a = e.charAt(ze), O.test(a) ? ze++ : (a = n, 0 === Ne && Je(ue));else u = n;
      u !== n ? (Ie = t, t = Oe()) : (ze = t, t = n);
    } else ze = t, t = n;
    return t === n && (t = ze, (r = Xe()) !== n && (Ie = t, r = Pe(r)), (t = r) === n && (t = ze, (r = tt()) !== n && (Ie = t, r = qe()), t = r)), Ne--, t === n && (r = n, 0 === Ne && Je(re)), t;
  }
  function ct() {
    var t, r, u, a, o, c, i, l;
    for (t = ze, r = e.charAt(ze), P.test(r) ? ze++ : (r = n, 0 === Ne && Je(ae)), r === n && (r = null), u = ze, a = [], o = e.charAt(ze), q.test(o) ? ze++ : (o = n, 0 === Ne && Je(oe)); o !== n;) a.push(o), o = e.charAt(ze), q.test(o) ? ze++ : (o = n, 0 === Ne && Je(oe));
    if (46 === e.charCodeAt(ze) ? (o = $, ze++) : (o = n, 0 === Ne && Je(ce)), o !== n) {
      if (c = [], i = e.charAt(ze), q.test(i) ? ze++ : (i = n, 0 === Ne && Je(oe)), i !== n) for (; i !== n;) c.push(i), i = e.charAt(ze), q.test(i) ? ze++ : (i = n, 0 === Ne && Je(oe));else c = n;
      c !== n ? u = a = [a, o, c] : (ze = u, u = n);
    } else ze = u, u = n;
    if (u === n) if (u = [], a = e.charAt(ze), q.test(a) ? ze++ : (a = n, 0 === Ne && Je(oe)), a !== n) for (; a !== n;) u.push(a), a = e.charAt(ze), q.test(a) ? ze++ : (a = n, 0 === Ne && Je(oe));else u = n;
    if (u !== n) {
      if (a = ze, 101 === e.charCodeAt(ze) ? (o = C, ze++) : (o = n, 0 === Ne && Je(ie)), o !== n) {
        if (c = e.charAt(ze), P.test(c) ? ze++ : (c = n, 0 === Ne && Je(ae)), c === n && (c = null), i = [], l = e.charAt(ze), q.test(l) ? ze++ : (l = n, 0 === Ne && Je(oe)), l !== n) for (; l !== n;) i.push(l), l = e.charAt(ze), q.test(l) ? ze++ : (l = n, 0 === Ne && Je(oe));else i = n;
        i !== n ? a = o = [o, c, i] : (ze = a, a = n);
      } else ze = a, a = n;
      a === n && (a = null), Ie = t, t = ke();
    } else ze = t, t = n;
    return t;
  }
  function it(e, t) {
    return e.map(function (e) {
      return e[t];
    });
  }
  function lt(e, t, r) {
    return [e].concat(it(t, r));
  }
  if (r = o(), t.peg$library) return {
    peg$result: r,
    peg$currPos: ze,
    peg$FAILED: n,
    peg$maxFailExpected: Me,
    peg$maxFailPos: Se
  };
  if (r !== n && ze === e.length) return r;
  throw r !== n && ze < e.length && Je(Le()), Ke(Me, Se < e.length ? e.charAt(Se) : null, Se < e.length ? He(Se, Se + 1) : He(Se, Se));
}
function d(e) {
  if (!e || 0 === e.length) return null;
  if ("string" == typeof e) {
    const t = y(e);
    return t && 0 !== t.length ? t : null;
  }
  const t = e.map(e => {
    if (!Number.isFinite(e.scale) || e.scale <= 0) throw new u("effect:invalid-scale", "scale must be finite and greater than 0", {
      stop: e
    });
    return {
      scale: e.scale,
      effects: y(e.value)
    };
  });
  t.sort((e, t) => t.effects.length - e.effects.length);
  for (let r = 0; r < t.length - 1; r++) {
    if (!f(t[r].effects, t[r + 1].effects)) throw new u("effect:interpolation-impossible", "Cannot interpolate by scale between 2 lists of mixed effects", {
      a: t[r].effects,
      b: t[r + 1].effects
    });
    p(t[r].effects, t[r + 1].effects);
  }
  return t.sort((e, t) => t.scale - e.scale), t;
}
function y(e) {
  let t;
  if (!e) return [];
  try {
    t = v(e);
  } catch (r) {
    throw new u("effect:invalid-syntax", "Invalid effect syntax", {
      value: e,
      error: r
    });
  }
  return t.map(e => w(e));
}
function w(e) {
  try {
    switch (e.name) {
      case "grayscale":
      case "sepia":
      case "saturate":
      case "invert":
      case "brightness":
      case "contrast":
        return x(e);
      case "opacity":
        return A(e);
      case "hue-rotate":
        return b(e);
      case "blur":
        return F(e);
      case "drop-shadow":
        return $(e);
      case "bloom":
        return C(e);
    }
  } catch (t) {
    throw t.details.filter = e, t;
  }
  throw new u("effect:unknown-effect", `Effect '${e.name}' is not supported`, {
    effect: e
  });
}
function x(e) {
  let t = 1;
  return E(e.parameters, 1), 1 === e.parameters.length && (t = S(e.parameters[0])), new a(e.name, t);
}
function A(e) {
  let t = 1;
  return E(e.parameters, 1), 1 === e.parameters.length && (t = S(e.parameters[0])), new o(t);
}
function b(e) {
  let t = 0;
  return E(e.parameters, 1), 1 === e.parameters.length && (t = N(e.parameters[0])), new c(t);
}
function F(e) {
  let t = 0;
  return E(e.parameters, 1), 1 === e.parameters.length && (t = T(e.parameters[0]), O(t, e.parameters[0])), new i(t);
}
function $(e) {
  const t = [];
  let r = null;
  for (const n of e.parameters) if ("color" === n.type) {
    if (t.length && Object.freeze(t), r) throw new u("effect:type-error", "Accepts only one color", {});
    r = U(n);
  } else {
    const e = T(n);
    if (Object.isFrozen(t)) throw new u("effect:type-error", "<length> parameters not consecutive", {
      lengths: t
    });
    t.push(e), 3 === t.length && O(e, n);
  }
  if (t.length < 2 || t.length > 3) throw new u("effect:type-error", `Expected <length>{2,3}, Actual: <length>{${t.length}}`, {
    lengths: t
  });
  return new l(t[0], t[1], t[2] || 0, r || D("black"));
}
function C(e) {
  let t = 1,
    r = 0,
    n = 0;
  return E(e.parameters, 3), e.parameters[0] && (t = S(e.parameters[0])), e.parameters[1] && (r = T(e.parameters[1]), O(r, e.parameters[1])), e.parameters[2] && (n = S(e.parameters[2])), new s(t, r, n);
}
function E(e, t) {
  if (e.length > t) throw new u("effect:type-error", `Function supports up to ${t} parameters, Actual: ${e.length}`, {
    parameters: e
  });
}
function j(e) {
  if ("color" === e.type) return "<color>";
  if (e.unit) {
    if (e.unit in I) return "<length>";
    if (e.unit in k) return "<angle>";
    if ("%" === e.unit) return "<percentage>";
  }
  return "<double>";
}
function O(e, t) {
  if (e < 0) throw new u("effect:type-error", `Negative values are not allowed, Actual: ${e}`, {
    term: t
  });
}
function P(e) {
  if ("quantity" !== e.type || null !== e.unit) throw new u("effect:type-error", `Expected <double>, Actual: ${j(e)}`, {
    term: e
  });
}
function q(e) {
  if ("quantity" !== e.type || null !== e.unit && "%" !== e.unit) throw new u("effect:type-error", `Expected <double> or <percentage>, Actual: ${j(e)}`, {
    term: e
  });
}
h(m, Error), m.prototype.format = function (e) {
  var t = "Error: " + this.message;
  if (this.location) {
    var r,
      n = null;
    for (r = 0; r < e.length; r++) if (e[r].source === this.location.source) {
      n = e[r].text.split(/\r\n|\n|\r/g);
      break;
    }
    var u = this.location.start,
      a = this.location.source && "function" == typeof this.location.source.offset ? this.location.source.offset(u) : u,
      o = this.location.source + ":" + a.line + ":" + a.column;
    if (n) {
      var c = this.location.end,
        i = g("", a.line.toString().length, " "),
        l = n[u.line - 1],
        s = (u.line === c.line ? c.column : l.length + 1) - u.column || 1;
      t += "\n --\x3e " + o + "\n" + i + " |\n" + a.line + " | " + l + "\n" + i + " | " + g("", u.column - 1, " ") + g("", s, "^");
    } else t += "\n at " + o;
  }
  return t;
}, m.buildMessage = function (e, t) {
  var r = {
    literal: function (e) {
      return '"' + u(e.text) + '"';
    },
    class: function (e) {
      var t = e.parts.map(function (e) {
        return Array.isArray(e) ? a(e[0]) + "-" + a(e[1]) : a(e);
      });
      return "[" + (e.inverted ? "^" : "") + t.join("") + "]";
    },
    any: function () {
      return "any character";
    },
    end: function () {
      return "end of input";
    },
    other: function (e) {
      return e.description;
    }
  };
  function n(e) {
    return e.charCodeAt(0).toString(16).toUpperCase();
  }
  function u(e) {
    return e.replace(/\\/g, "\\\\").replace(/"/g, '\\"').replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (e) {
      return "\\x0" + n(e);
    }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (e) {
      return "\\x" + n(e);
    });
  }
  function a(e) {
    return e.replace(/\\/g, "\\\\").replace(/\]/g, "\\]").replace(/\^/g, "\\^").replace(/-/g, "\\-").replace(/\0/g, "\\0").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/[\x00-\x0F]/g, function (e) {
      return "\\x0" + n(e);
    }).replace(/[\x10-\x1F\x7F-\x9F]/g, function (e) {
      return "\\x" + n(e);
    });
  }
  function o(e) {
    return r[e.type](e);
  }
  function c(e) {
    var t,
      r,
      n = e.map(o);
    if (n.sort(), n.length > 0) {
      for (t = 1, r = 1; t < n.length; t++) n[t - 1] !== n[t] && (n[r] = n[t], r++);
      n.length = r;
    }
    switch (n.length) {
      case 1:
        return n[0];
      case 2:
        return n[0] + " or " + n[1];
      default:
        return n.slice(0, -1).join(", ") + ", or " + n[n.length - 1];
    }
  }
  function i(e) {
    return e ? '"' + u(e) + '"' : "end of input";
  }
  return "Expected " + c(e) + " but " + i(t) + " found.";
};
const k = {
  deg: 1,
  grad: .9,
  rad: 180 / Math.PI,
  turn: 360
};
function z(e) {
  if ("quantity" !== e.type || !(0 === e.value && null === e.unit || e.unit && null != k[e.unit])) throw new u("effect:type-error", `Expected <angle>, Actual: ${j(e)}`, {
    term: e
  });
}
const I = {
  px: 1,
  cm: 96 / 2.54,
  mm: 96 / 2.54 / 10,
  in: 96,
  pc: 16,
  pt: 96 / 72
};
function R(e) {
  if ("quantity" !== e.type || !(0 === e.value && null === e.unit || e.unit && null != I[e.unit])) throw new u("effect:type-error", `Expected <length>, Actual: ${j(e)}`, {
    term: e
  });
}
function S(e) {
  q(e);
  const t = e.value;
  return O(t, e), "%" === e.unit ? .01 * t : t;
}
function M(e) {
  return P(e), O(e.value, e), e.value;
}
function N(e) {
  return z(e), e.value * k[e.unit] || 0;
}
function T(e) {
  return R(e), e.value * I[e.unit] || 0;
}
function U(t) {
  switch (t.colorType) {
    case "hex":
      return e(t.value);
    case "named":
      return D(t.value);
    case "function":
      return G(t.value);
  }
}
function D(e) {
  if (!t(e)) throw new u("effect:unknown-color", `color '${e}' isn't valid`, {
    namedColor: e
  });
  return r(e);
}
const L = /^rgba?/i,
  B = /^hsla?/i;
function G(e) {
  if (E(e.parameters, 4), L.test(e.name)) return [S(e.parameters[0]), S(e.parameters[1]), S(e.parameters[2]), e.parameters[3] ? S(e.parameters[3]) : 1];
  if (B.test(e.name)) return n(M(e.parameters[0]), S(e.parameters[1]), S(e.parameters[2]), e.parameters[3] ? S(e.parameters[3]) : 1);
  throw new u("effect:syntax-error", `Invalid color function '${e.name}'`, {
    colorFunction: e
  });
}
export { d as parse };